<template>
  <div class="footer" style="z-index: 9999;position: fixed">
    <div class="_menu_footer"></div>
    <div class="_fix_footer">
      <div class="_menu" @click="$router.push('/more')">
        <template v-if="step === 1"><img src="../../status/img/menu_app_current.png" /></template>
        <template v-else><img src="../../status/img/menu_app.png" /></template>
      </div>
      <div class="_menu" @click="$router.push('/')">
        <template v-if="step === 2"><img src="../../status/img/menu_home_current.png" /></template>
        <template v-else><img src="../../status/img/menu_home.png" /></template>
      </div>
      <div class="_menu" @click="$router.push('/me')">
        <template v-if="step === 3"><img src="../../status/img/menu_me_current.png" /></template>
        <template v-else><img src="../../status/img/menu_me.png" /></template>
      </div>
    </div>

    <div class="coming-soon" v-if="comingSoon" >
      <div class="_content">
        <div class="_window"><img src="../../status/img/icon_coming.png" /></div>
        <div class="_closed" @click="comingSoon = false"><img src="../../status/img/icon_closed.png" /></div>
      </div>
      <div class="_bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      comingSoon: false,
    };
  },

  created() {
  },

  mounted() {
  },

  methods: {
    onClickComingSoon() {
      this.comingSoon = true;
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  ._menu_footer {
    height: 1.6rem;
  }
  ._fix_footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1.6rem;
    background: #fff;
    ._menu {
      display: block;
      width: 33.33%;
      float: left;
      text-align: center;
      img {
        width: 0.8rem;
        margin-top: 0.4rem;
      }
    }
  }
}
.coming-soon {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  ._content {
    width: 100%;
    position: absolute;
    top: 2rem;
    left: 0;
    ._window {
      padding: 0 0.75rem;
      img {
        display: block;
        width: 100%;
      }
    }
    ._closed {
      padding: 0.4rem 0;
      text-align: center;
      img {
        width: 1.2rem;
      }
    }
  }
}
</style>
